import { has } from 'lodash-es';
import { randomIntFromInterval } from '../../util/math';
import { Model, CustomizationBase, OutputMetadataItem, AeTitleRule, DicomRule, DicomAttributeRule, ModelVisibility, CustomizationOutput, isCustomizationOutput, CustomizationObjectType, METADATA_FILENAME_ATTRIBUTE } from '../global-types/customization-types';

export const DEFAULT_CONTOUR_FILENAME = 'mv-rt.dcm';
export const DEFAULT_INTERPRETED_TYPE = 'ORGAN';

/** One of currently two supported physical properties: REL_ELEC_DENSITY */
export const PHYSICAL_PROPERTY_REL_ELEC_DENSITY = 'REL_ELEC_DENSITY';

/** One of currently two supported physical properties: REL_MASS_DENSITY */
export const PHYSICAL_PROPERTY_REL_MASS_DENSITY = 'REL_MASS_DENSITY';

export const SUPPORTED_PHYSICAL_PROPERTIES = [PHYSICAL_PROPERTY_REL_ELEC_DENSITY, PHYSICAL_PROPERTY_REL_MASS_DENSITY];

export const EXPORT_CONTOUR_JSON_FILE_PREFIX = 'mvision-contour-';

/** Prefix to use for contouring AE Titles */
export const AETITLE_PREFIX_CONTOUR = 'MV';

/** The preset options for output metadata items to show for user for contouring outputs. */
export const CONTOUR_METADATA_ATTRIBUTE_PRESETS = [
    METADATA_FILENAME_ATTRIBUTE,
    'SeriesDescription',
    'StructureSetLabel',
    'StructureSetName'
]

/** Currently supported physical property attribute names. TODO: use the const assignments above in these when we update to TypeScript 5.0+ */
export enum PhysicalProperty { NotSet = 'NotSet', RelElecDensity = 'REL_ELEC_DENSITY', RelMassDensity = 'REL_MASS_DENSITY' };


/** A single customization output (ie. a result file) for a contouring model. A customization base contains one or more of these. */
export type ContouringCustomizationOutput = CustomizationOutput & {
    /** ROI customizations that belong to this customization output. */
    rois: string[]; // RoiCustomization ids
};

/** Utility type for ROI coding schemes (aka FMA IDs). */
export type CodingScheme = {
    codingSchemeCodeValue?: string;
    codingSchemeCodeMeaning?: string;
    codingSchemeDesignator?: string;
    codingSchemeVersion?: string;
    codingSchemeMappingResource?: string;
    codingSchemeContextGroupVersion?: string;
    codingSchemeContextIdentifier?: string;
    codingSchemeContextUid?: string;
    codingSchemeMappingResourceUid?: string;
    codingSchemeMappingResourceName?: string;

    /** True if this object has a valid and non-empty coding scheme, false otherwise. */
    hasCodingScheme: boolean;
};

export type CodingSchemeKeys = keyof CodingScheme;

/** Customization settings for a single contouring ROI. */
export type ContouringRoi = CodingScheme & {
    /** Redux ID of this roi customization. */
    id: string;
    /** The simple ROI or a more complex ROI operation that this customization applies to. */
    operation: string;
    /** The name of the output ROI. This can be used to change the name of the ROI from its default, or to create ROIs with new names based on a more complex ROI operation. */
    name: string;
    /** Contour color of the output ROI. RGB, 0-255. */
    color: [number, number, number];
    /** Interpreted ROI type of the output ROI.  */
    interpretedType: string;
    /** True if the output ROI should be included in its related customization output, false if it should be omitted. */
    isIncluded: boolean;
    /** Built-in ROIs cannot be deleted or their operations be changed. */
    isBuiltInRoi: boolean;

    /** Single physical property attribute. TODO: support multiple of these by making a new Entity Adapter. */
    physicalPropertyAttribute: PhysicalProperty;
    /** Value for the single physical property attribute. */
    physicalPropertyValue: string;


    /** True if this roi customization has unsaved changes, false otherwise. */
    isModified: boolean;
    /** If true, scroll the viewport to show this item when it's mounted into DOM. This is used to auto-scroll to newly added items. */
    scrollToView: boolean;

    /** Parent model customization output this roi customization belongs to. */
    customizationOutputId?: string;  // this is optional because it's usually set post-constructor
    /** Parent global roi customization this roi customization belongs to, if any. */
    globalRoiId?: string | undefined;  // this is optional because it's usually set post-constructor
};

export type GlobalContouringRoi = CodingScheme & {
    id: string;
    operation: string;  // NOTE: global rois are grouped primarily by operation (but of course all values must match)
    name: string;
    isIncluded: boolean; // TODO: should this be here?
    color: [number, number, number];
    isModified: boolean;
    interpretedType: string;

    /** Single physical property attribute. TODO: support multiple of these by making a new Entity Adapter. */
    physicalPropertyAttribute: PhysicalProperty;
    /** Value for the single physical property attribute. */
    physicalPropertyValue: string;

    /** built-in ROIs cannot be deleted or their operations be changed. a global roi is a built-in ROI if ANY of the rois its covers
     * has canBeDeleted set to false. */
    isBuiltInRoi: boolean;

    /** IDs of RoiCustomization entities that are covered under this global customization entry. */
    coveredRois: string[];

    /** IDs of RoiCustomization entities that are exempt from this global customization, and have their own unique customizations. */
    excludedRois: string[];
}

export type ContouringCustomizationEntities = {
    models: Model[];
    customizationBases: CustomizationBase[];
    contouringOutputs: ContouringCustomizationOutput[];
    outputMetadata: OutputMetadataItem[];
    contouringRois: ContouringRoi[];
    contouringGlobalRois: GlobalContouringRoi[];
    aeTitleRules: AeTitleRule[];
    dicomRules: DicomRule[];
    dicomAttributeRules: DicomAttributeRule[];
};

export type ContouringCustomizationEntitiesForExport = {
    models: Model[];
    customizationBases: CustomizationBase[];
    contouringOutputs: ContouringCustomizationOutput[];
    outputMetadata: OutputMetadataItem[];
    contouringRois: ContouringRoi[];
    aeTitleRules: AeTitleRule[];
    dicomRules: DicomRule[];
    dicomAttributeRules: DicomAttributeRule[];
};

/** View model entities related to customization output */
export type ContouringCustomizationOutputEntities = {
    customizationOutputs: ContouringCustomizationOutput[];
    modelCustomizationsMetadata: OutputMetadataItem[];
    roiCustomizations: ContouringRoi[];
};


// *** type guards ***

export const isContouringCustomizationOutput = (obj: any): obj is ContouringCustomizationOutput => {
    const output = obj as ContouringCustomizationOutput;
    return isCustomizationOutput(obj)
        && output.rois !== undefined
        // must not look like doseOutput
        && !has(output, 'targets');
}

export const isContouringRoi = (obj: any): obj is ContouringRoi => {
    const roi = obj as ContouringRoi;
    return roi.operation !== undefined && roi.color !== undefined && (obj as GlobalContouringRoi).coveredRois === undefined
        // must not look like doseRoi
        && !has(roi, 'regExp');
}

export const isGlobalRoiCustomization = (obj: any): obj is GlobalContouringRoi => {
    const globalRoi = obj as GlobalContouringRoi;
    return globalRoi.operation !== undefined && globalRoi.color !== undefined && globalRoi.coveredRois !== undefined && globalRoi.interpretedType !== undefined;
}



export const generateRandomColor = (): [number, number, number] => {
    return [randomIntFromInterval(0, 255), randomIntFromInterval(0, 255), randomIntFromInterval(0, 255)];
}
