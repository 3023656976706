import { createSlice, PayloadAction } from "@reduxjs/toolkit";


export type TokenSliceState = {
    /** Hold the credentials error if any */
    credentialsError: string | null;

    /** Holds the key if daemon credentials were regenerated */
    credentialsKey: string | null,

    /** Holds the secret if daemon credentials were regenerated */
    credentialsSecret: string | null
};


export const initialState: TokenSliceState = {
    credentialsError: null,
    credentialsKey: null,
    credentialsSecret: null
};

/** Redux store slice for interacting with one-time tokens and daemon credentials generation that user has access to (these
 * used to be called app clients).
) */
const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        /**
         * Stores the daemon credentials if/when they were generated
         * @param action.key Credentials key
         * @param action.secret Credentials secret
         * @param action.error An optional error message.
         */
        daemonCredentialsSet(state, action: PayloadAction<{ key: string | null, secret: string | null, error?: string }>) {
            const { key, secret, error } = action.payload;

            state.credentialsError = error || null;
            state.credentialsKey = !error ? key : null;
            state.credentialsSecret = !error ? secret : null;
        },

        credentialsRegenerationRequested(state, action: PayloadAction<{ appClient: string | undefined, sessionID: string | undefined}>) {},

        tokenAssociationApproved(state, action: PayloadAction<{ token: string | undefined, sessionID: string | undefined}>) {}
    },
    selectors: {
        selectDaemonCredentials: (state) => ({ key: state.credentialsKey, secret: state.credentialsSecret, error: state.credentialsError }),
    }
});

export const { daemonCredentialsSet, credentialsRegenerationRequested, tokenAssociationApproved } = tokenSlice.actions;
export const { getInitialState, selectors: tokenSelectors } = tokenSlice;
export default tokenSlice.reducer;
