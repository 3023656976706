import { call, put, fork, select, take } from "typed-redux-saga";
import { get } from "lodash-es";
import { configurationTargetSelectors } from "../configurationTarget/configurationTargetSlice";
import { daemonCredentialsSet, credentialsRegenerationRequested, tokenAssociationApproved } from "./tokenSlice";
import { callApi } from "../sagas";
import { MVisionAppClient } from "../configurationTarget/mvision-client-list";


function* regenerateCredentialsSaga(appClient: MVisionAppClient | undefined, sessionID: string | undefined) {
    yield* callApi({
        doApiCall: function* (client) {
            if (!sessionID) {
                return undefined;
            }
            const credentials = appClient === undefined ? null : yield* call(async () => client.regenerateCredentialsAsync(appClient, sessionID));
            return credentials;
        },
        onSuccess: function* (result) {
            if (result) {
                yield* put(daemonCredentialsSet({ key: result.key, secret: result.secret }));
            }
        },
        onFailure: function* (error) {
            console.error('Was not able to regenerate the daemon credentials');
            const errorMessage = `${get(error, 'problem', 'Error')}: ${get(error, 'message', 'Unknown error')}`;
            yield* put(daemonCredentialsSet({ key: null, secret: null, error: errorMessage }));
        }
    });
}

function* tokenAssocationSaga(appClient: MVisionAppClient | undefined, token: string | undefined, sessionID: string | undefined) {
    yield* callApi({
        doApiCall: function* (client) {
            console.log(sessionID, token);
            if (!sessionID || !token) {
                console.log('Session ID or token undefined!');
                return undefined;
            }
            appClient === undefined ? null : yield* call(async () => client.tokenAssociationApproveAsync(appClient, token, sessionID));
        },
        onSuccess: function* (result) {
            console.info('Token was approved successfully');
        },
        onFailure: function* (error) {
            console.error('Was not able to approve the token');
        }
    });
}

function* watchCredentialsRegenerationSaga() {
    while (true) {
        const action = yield* take(credentialsRegenerationRequested);
        const currentTarget = yield* select(configurationTargetSelectors.selectCurrent);
        yield* fork(regenerateCredentialsSaga, currentTarget, action.payload.sessionID);
    }
}

function* watchTokenAssociationApprovedSaga() {
    while (true) {
        const action = yield* take(tokenAssociationApproved);
        const currentTarget = yield* select(configurationTargetSelectors.selectCurrent);
        yield* fork(tokenAssocationSaga, currentTarget, action.payload.token, action.payload.sessionID);
    }
}

/** Returns all relevant watches to be added to a main root watch saga */
export function getWatchesForTokenSagas() {
    return [
        watchCredentialsRegenerationSaga(),
        watchTokenAssociationApprovedSaga(),
    ];
}
