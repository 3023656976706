import { z } from 'zod';

/** Schema for license status objects. */
const LicenseStatusSchema = z.object({
    processed_tasks: z.number(),
    t_creation_u: z.number(),
    t_expiry_u: z.number(),
    id: z.string(),
    installation_id: z.string(),
    model_names: z.array(z.string()),
    quota: z.number(),
    quota_left: z.number(),
    t_unlimited_use_expiry_u: z.number(),
    user_id: z.string(),
    user_name: z.string(),
})
/** transform schema to javascript case instead of underscores */
.transform((input) => ({
    processedTasks: input.processed_tasks,
    creationTime: input.t_creation_u,
    expiryTime: input.t_expiry_u,
    id: input.id,
    installationId: input.installation_id,
    modelNames: input.model_names,
    quota: input.quota,
    quotaLeft: input.quota_left,
    unlimitedUseExpiryTime: input.t_unlimited_use_expiry_u,
    userId: input.user_id,
    userName: input.user_name,
}));

/** Schema for collection of license status objects. */
const LicenseStatusCollectionSchema = z.array(LicenseStatusSchema);


/** A single license status retrieved from backend. A user or client app can have multiple licenses. */
export type LicenseStatus = z.infer<typeof LicenseStatusSchema>;

/** Converts input JSON object to an array of License Status objects, or throws an error if JSON is in invalid format. */
export const convertJsonObjectToLicenseStatuses = (jsonObject: any): LicenseStatus[] => {
    const parsedLicenses = LicenseStatusCollectionSchema.safeParse(jsonObject);

    if (parsedLicenses.success) {
        return parsedLicenses.data;
    } else {
        const errorMsg = `Received license JSON has invalid properties: ${parsedLicenses.error}`;
        console.log(errorMsg);
        console.log(jsonObject);
        throw new Error(errorMsg);
    }
}
