import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootSaga from './sagas';

import appConfigReducers, { getInitialState as getAppConfigInitialState } from './appConfig/appConfigSlice';
import appStatusReducers, { getInitialState as getAppStatusInitialState } from './appStatus/appStatusSlice';
import appVersionReducers, { getInitialState as getAppVersionInitialState } from './appVersion/appVersionSlice';
import authReducers, { getInitialState as getAuthInitialState } from './auth/authSlice';
import configurationTargetReducers, { getInitialState as getConfigurationTargetInitialState } from './configurationTarget/configurationTargetSlice';
import tokenReducers, { getInitialState as getTokenInitialState } from './token/tokenSlice';
import contouringConfigReducers, { getInitialState as getContouringInitialState } from './contouring/contouringSlice';
import daemonReducers, { getInitialState as getDaemonInitialState } from './daemon/daemonSlice';
import doseConfigReducers, { getInitialState as getDoseInitialState } from './dose/doseSlice';
import licenseReducers, { getInitialState as getLicenseInitialState } from './license/licenseSlice';
import textEditorReducers, { getInitialState as getTextEditorInitialState } from './textEditor/textEditorSlice';
import userReducers, { getInitialState as getUserInitialState } from './user/userSlice';

const sagaMiddleware = createSagaMiddleware();

// all reducers combined into one root reducer. Exported as convenience
// for tests
export const reducers = {
  appConfig: appConfigReducers,
  appStatus: appStatusReducers,
  appVersion: appVersionReducers,
  auth: authReducers,
  configurationTarget: configurationTargetReducers,
  token: tokenReducers,
  contouring: contouringConfigReducers,
  daemon: daemonReducers,
  dose: doseConfigReducers,
  license: licenseReducers,
  textEditor: textEditorReducers,
  user: userReducers,
};

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(sagaMiddleware),
});


sagaMiddleware.run(rootSaga, store.dispatch);

// export the actual store
export default store;

// export the type of the store
export type StoreState = ReturnType<typeof store.getState>;

// export initial store state (as convenience for tests)
export const getInitialStoreState = (): StoreState => ({
  appConfig: getAppConfigInitialState(),
  appStatus: getAppStatusInitialState(),
  appVersion: getAppVersionInitialState(),
  auth: getAuthInitialState(),
  configurationTarget: getConfigurationTargetInitialState(),
  token: getTokenInitialState(),
  contouring: getContouringInitialState(),
  daemon: getDaemonInitialState(),
  dose: getDoseInitialState(),
  license: getLicenseInitialState(),
  textEditor: getTextEditorInitialState(),
  user: getUserInitialState(),
});
